<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import store from "../../store.js";
  import Grid from "../atoms/Grid.svelte";
  import queryString from "query-string";
  onMount(() => {
    let placesAutocomplete = places({
      appId: "pl241CBYJR2T",
      apiKey: "f4facf7aa7bff84e7786c8470eeddc98",
      container: document.querySelector("#input-city")
    }).configure({ type: "city" });

    placesAutocomplete.on("change", e => {
      const currentCity = e.suggestion.city || e.suggestion.name;
      const currentCountry = e.suggestion.country;
      store.setCountry(currentCountry);
      store.setCity(currentCity);
      navigate(`/hotels/${$store.country}/${currentCity}`, { replace: true });
    });

    placesAutocomplete.on("clear", () => {
      store.setCountry("");
      store.setCity("");
    });
  });
</script>

<style>
  input {
    padding: 1rem;
    width: 100%;
    height: 100%;
    border: none;
    -webkit-appearance: none;
    border-radius: 0;
  }
  form {
    padding: 1rem;
    display: grid;
    grid-gap: 0.5rem 1rem;
    grid-template-columns: 1fr 1fr;
  }
  label {
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.5rem 1rem;
    }
    label {
      display: none;
    }
  }
</style>

<form>
  <label for="input-hotel">Hotel</label>
  <label for="input-city">City</label>
  <input
    id="input-hotel"
    type="search"
    placeholder="Hotel name"
    on:input={e => {
      store.setHotel(e.target.value);
      navigate(`/hotels/${$store.country}/${$store.city}/${e.target.value}`, {
        replace: true
      });
    }} />
  <input id="input-city" class="form-control" placeholder="Paris" />
</form>
