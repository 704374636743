import gql from 'graphql-tag';

export const COUNTRY = gql`
  query COUNTRY($limit: Int = 60) {
    country(limit: $limit){
      id
      name
    }
  }
`

export const CITY = gql`
query CITY($limit: Int = 60, $country: String) {
  city(limit: $limit, where: {country: {name: {_ilike: $country}}}) {
    id
    name
    tripadvisorUrl
    hotels_aggregate {
      aggregate {
        count
      }
    }
  }
}`

export const SEARCH = gql`
query SEARCH($limit: Int = 20, $hotel: String, $city: String, $country: String) {
  hotels(limit: $limit, where: {name: {_ilike: $hotel}, city: {name: {_ilike: $city}, country: {name: {_ilike: $country}}}}, order_by: {rating: desc_nulls_last}) {
    id
    name
    tripadvisorUrl
    price
    rating
    ratingText
    roomAmount
    city {
      name
      country {
        name
      }
    }
  }
}`
export const TRIPADVISOR = gql`
query ReviewListQuery($locationId: Int!, $offset: Int, $limit: Int, $filters: [FilterConditionInput!], $prefs: ReviewListPrefsInput, $initialPrefs: ReviewListPrefsInput, $filterCacheKey: String, $prefsCacheKey: String, $keywordVariant: String!, $needKeywords: Boolean = true) {
  cachedFilters: personalCache(key: $filterCacheKey)
  cachedPrefs: personalCache(key: $prefsCacheKey)
  locations(locationIds: [$locationId]) {
    locationId
    name
    reviewSummary {
      rating
      count
      __typename
    }
    keywords(variant: $keywordVariant) @include(if: $needKeywords) {
      keywords {
        keyword
        __typename
      }
      __typename
    }
    ... on LocationInformation {
      name
      __typename
    }
    ... on LocationInformation {
      locationId
      currentUserOwnerStatus {
        isValid
        __typename
      }
      __typename
    }
    ... on LocationInformation {
      locationId
      parentGeoId
      accommodationCategory
      __typename
    }
    reviewList(page: {offset: $offset, limit: $limit}, filters: $filters, prefs: $prefs, initialPrefs: $initialPrefs, filterCacheKey: $filterCacheKey, prefsCacheKey: $prefsCacheKey) {
      preferredReviewIds
      alertStatusCount
      reviews {
        ... on Review {
          id
          url
          location {
            locationId
            name
            __typename
          }
          createdDate
          publishedDate
          userProfile {
            contributionCounts {
              sumAllUgc
              helpfulVote
              __typename
            }
            __typename
          }
          __typename
        }
        ... on Review {
          title
          language
          url
          __typename
        }
        ... on Review {
          language
          translationType
          __typename
        }
        ... on Review {
          roomTip
          __typename
        }
        ... on Review {
          additionalRatings {
            rating
            ratingLabel
            __typename
          }
          __typename
        }
        ... on Review {
          tripInfo {
            tripType
            __typename
          }
          __typename
        }
        ... on Review {
          language
          translationType
          mgmtResponse {
            id
            language
            translationType
            __typename
          }
          __typename
        }
        ... on Review {
          text
          publishedDate
          username
          connectionToSubject
          language
          mgmtResponse {
            id
            text
            language
            publishedDate
            username
            connectionToSubject
            __typename
          }
          __typename
        }
        ... on Review {
          id
          locationId
          title
          text
          rating
          absoluteUrl
          mcid
          translationType
          mtProviderId
          photos {
            id
            statuses
            photoSizes {
              url
              width
              height
              __typename
            }
            __typename
          }
          __typename
        }
        ... on Review {
          mgmtResponse {
            id
            __typename
          }
          provider {
            isLocalProvider
            __typename
          }
          __typename
        }
        ... on Review {
          translationType
          location {
            locationId
            parentGeoId
            __typename
          }
          provider {
            isLocalProvider
            isToolsProvider
            __typename
          }
          original {
            id
            url
            locationId
            userId
            language
            submissionDomain
            __typename
          }
          __typename
        }
        ... on Review {
          locationId
          mcid
          attribution
          __typename
        }
        ... on Review {
          locationId
          helpfulVotes
          photoIds
          route {
            url
            __typename
          }
          status
          userId
          __typename
        }
        ... on Review {
          text
          language
          __typename
        }
        ... on Review {
          locationId
          absoluteUrl
          mcid
          translationType
          mtProviderId
          originalLanguage
          rating
          __typename
        }
        ... on Review {
          id
          locationId
          title
          rating
          absoluteUrl
          mcid
          translationType
          mtProviderId
          alertStatus
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
`