<script>
  import { getClient, query } from "svelte-apollo";
  import { navigate, link } from "svelte-routing";
  import Link from "../atoms/Link.svelte";
  import Grid from "../atoms/Grid.svelte";
  import Spinner from "../atoms/Spinner.svelte";
  import { COUNTRY } from "../../query";
  import store from "../../store.js";
  const client = getClient();
  const countries = query(client, { query: COUNTRY });
</script>

<style>
  ul {
    display: grid;
    padding: 0;
    grid-gap: 1rem;
    list-style-type: none;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
</style>

<Grid>
  <div>
    <h2>Countries</h2>
    <h4>Select a country</h4>
  </div>
  {#await $countries}
    <Spinner />
  {:then result}
    <ul>
      {#each result.data.country as country (country.id)}
        <li>
          <Link
            href="/hotels/{country.name}"
            on:click={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              store.setCountry(country.name);
            }}>
             {country.name}
          </Link>
        </li>
      {/each}
    </ul>
  {:catch error}
    <h3>{error}</h3>
  {/await}
</Grid>
