<script>
  import { link } from "svelte-routing";
  export let href;
</script>

<style>
  a {
    display: block;
    color: darkgrey;
    background: white;
    padding: 1rem 2rem;
    text-transform: capitalize;
    text-decoration: none;
    text-align: center;
  }

  a:hover {
    text-decoration: underline;
  }

  /* a:after {
    display: inline;
    margin-left: 0.2rem;
    content: ">";
  } */
</style>

<a {href} replace use:link on:click>
  <slot />
</a>
