<script>
  import { getClient, query } from "svelte-apollo";
  import axios from "axios";
  import { navigate } from "svelte-routing";
  import * as R from "ramda";
  import Grid from "../atoms/Grid.svelte";
  import Spinner from "../atoms/Spinner.svelte";
  import Link from "../atoms/Link.svelte";
  import { CITY } from "../../query";
  import store from "../../store.js";
  const client = getClient();
  const cities = query(client, {
    query: CITY,
    variables: { country: `%${$store.country}%` }
  });
  $: cities.refetch({ country: `%${$store.country}%` });
  const getCount = R.path(["hotels_aggregate", "aggregate", "count"]);
  const onClick = ({ id, name, tripadvisorUrl }) => async () => {
    store.setCity(name);
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Scrape missing cities
    // const { data } = await axios.post(`http://localhost:3000/api/hotels.js`, {
    //   id,
    //   tripadvisorUrl
    // });
    // await cities.refetch({ country: `%${$store.country}%` });
  };
</script>

<style>
  ul {
    display: grid;
    padding: 0;
    grid-gap: 1rem;
    list-style-type: none;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
</style>

{#if $store.country}
  <Grid>
    <div>
      <h2>
        Cities in
        <span style="color:grey;">
           {R.replace(/^./, R.toUpper)($store.country)}
        </span>
      </h2>
      <h4>Select a city</h4>
    </div>
    {#await $cities}
      <Spinner />
    {:then result}
      <ul>
        {#each result.data.city as city (city.id)}
          <Link
            href="/hotels/{$store.country}/{city.name}"
            on:click={onClick(city)}>
             {city.name.replace(/\w+[.!?]?$/, '')}
            <sup>{getCount(city)}</sup>
          </Link>
        {/each}
      </ul>
    {:catch error}
      <h3>{JSON.stringify(error, null, 2)}</h3>
    {/await}
  </Grid>
{/if}
