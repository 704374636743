<script>
  import { getClient, query } from "svelte-apollo";
  import * as R from "ramda";
  import store from "../../store.js";
  import Grid from "../atoms/Grid.svelte";
  import Spinner from "../atoms/Spinner.svelte";
  import HotelCard from "../molecules/HotelCard.svelte";
  import { SEARCH } from "../../query";
  const client = getClient();
  const hotels = query(client, {
    query: SEARCH,
    variables: {
      country: `%${$store.country}%`,
      city: `%${$store.city}%`,
      hotel: `%${$store.hotel}%`
    }
  });
  $: hotels.refetch({
    country: `%${$store.country}%`,
    city: `%${$store.city}%`,
    hotel: `%${$store.hotel}%`
  });
</script>

{#if $store.city || $store.hotel}
  <Grid>
    {#await $hotels}
      <Spinner />
    {:then result}
      {#each result.data.hotels as hotel (hotel.id)}
        <HotelCard {hotel} />
      {/each}
    {:catch error}
      Error: {error}
    {/await}
  </Grid>
{/if}
