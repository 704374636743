<script>
  import { fade } from "svelte/transition";
  import Grid from "../atoms/Grid.svelte";
  import Button from "../atoms/Button.svelte";
  import Review from "./Review.svelte";
  import Card from "../atoms/Card.svelte";
  export let hotel;
  let open = false;
  let scraping = false;
  const toggle = () => (open = !open);

  const fetchTripadvisor = async ({ id, tripadvisorUrl }) => {
    const url = `https://api.hotelbedbugregistry.com/hotel.js`;
    scraping = true;
    const data = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ event: { data: { new: { id, tripadvisorUrl } } } })
    });
    scraping = false;
  };
  const locationId = /[d](\d+)/.exec(hotel.tripadvisorUrl)[1];
</script>

<style>
  a {
    color: black;
  }
  h3,
  h4 {
    text-transform: capitalize;
  }
</style>

<Card>
  <a href={hotel.tripadvisorUrl}>
    <h2>{hotel.name} </h2>
  </a>
  <h3>{hotel.city.country.name} </h3>
  <h4>{hotel.city.name.replace(/\w+[.!?]?$/, '')} </h4>
  <Review {locationId} />
</Card>
