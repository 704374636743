const uri = `https://staging-ota.graphql.valpas.io/v1alpha1/graphql`;
const wss = `wss://staging-ota.graphql.valpas.io/v1alpha1/graphql`;

// Yes yes - It's known
const API_KEY = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IlRlc3QiLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiY2xpZW50Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImNsaWVudCJ9LCJpYXQiOjE1NjI1NzIyMzZ9.pMW637QYb8Ir_RZFcBxtRDyhUUHrLXYxH9b-gUTCq-U`

// Ehk aika laitonta
// https://medium.com/netscape/hacking-it-out-when-cors-wont-let-you-be-great-35f6206cc646
const tripadvisorUri = `https://cors-anywhere.herokuapp.com/https://www.tripadvisor.com/data/graphql/batched`

import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";

import { BatchHttpLink } from "apollo-link-batch-http";

const batchedLink = new BatchHttpLink({ uri: tripadvisorUri });

const headers = {
    "Authorization": `Bearer ${API_KEY}`
}

const cache = new InMemoryCache();

const wsLink = new WebSocketLink({
    uri: wss,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
            headers
        },
    },
});

const httpLink = new HttpLink({
    uri,
    headers
});

const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
);

// no sub
export const client = new ApolloClient({ link: httpLink, cache });
export const tripadvisorClient = new ApolloClient({ link: batchedLink, cache })