import { writable } from 'svelte/store';
const createStore = () => {
    const [, , countryName = "", cityName = "", hotelName = ""] = window.location.pathname.split("/")
    const initialState = {
        country: decodeURI(countryName),
        city: decodeURI(cityName),
        hotel: decodeURI(hotelName)
    }
    const { subscribe, set, update } = writable(initialState)

    return {
        subscribe,
        setCountry: country => update(e => ({ ...e, city: "", country })),
        setCity: city => update(e => ({ ...e, city })),
        setHotel: hotel => update(e => ({ ...e, hotel })),
        reset: () => set(initialState)

    }

}
const store = createStore()
export default store