<script>
  import { setClient } from "svelte-apollo";
  import { Router, Route } from "svelte-routing";
  import { client, tripadvisorClient } from "./apollo.js";
  import store from "./store.js";
  import Container from "./components/atoms/Container.svelte";
  import Header from "./components/templates/Header.svelte";
  import Autocomplete from "./components/organisms/Autocomplete.svelte";
  import Recent from "./components/templates/Recent.svelte";
  import Country from "./components/templates/Country.svelte";
  import City from "./components/templates/City.svelte";
  import Hotels from "./components/templates/Hotels.svelte";
  setClient(client);
  // TODO refactor
  $: titles = Object.values($store).filter(e => e.length > 0);
  $: title = titles.length > 0 ? " | " + titles.join(" | ") : "";
</script>

<svelte:head>
  <title>Hotel Bedbug Registry{title}</title>
</svelte:head>

<Container>
  <Router>
    <Header />
    <Autocomplete />
    <Route path="hotels/:country/:city/:hotel" component={Hotels} />
    <Route path="hotels/:country/:city" component={Hotels} />
    <Route path="hotels/:country" component={Hotels} />
    <Country />
    <City />
    <!-- <Recent /> -->
  </Router>
</Container>
