<script>
  export let review;
  export let id = "No ID";
  export let createdDate = "";
  export let title = "";
  export let text = "";
  let open = false;
</script>

<style>
  div {
    padding: 1rem;
    margin-top: 1rem;
    border: solid lightgrey 1px;
  }

  span {
    cursor: pointer;
  }
</style>

<div>
  <h5> {review.createdDate} </h5>
  <h3>{review.title}</h3>
  {#if open}
    <p>{review.text}</p>
  {/if}
  <span style="padding: 1rem 0; color: grey;" on:click={() => (open = !open)}>
    {#if open}Show less{:else}Show more{/if}
  </span>

</div>
