<script>
  import * as R from "ramda";
  import { query } from "svelte-apollo";
  import { tripadvisorClient } from "../../apollo.js";
  import { TRIPADVISOR } from "../../query.js";
  import Spinner from "../atoms/Spinner.svelte";
  import ReviewCard from "./ReviewCard.svelte";

  export let locationId;

  const reviews = query(tripadvisorClient, {
    query: TRIPADVISOR,
    variables: {
      locationId,
      offset: 0,
      filters: [
        // {
        //   axis: "RATING",
        //   selections: ["1", "2"]
        // },
        // {
        //   axis: "LANGUAGE",
        //   selections: ["en"]
        // },
        {
          axis: "TEXT",
          selections: ["bedbug"]
        }
      ],
      prefs: null,
      initialPrefs: {},
      limit: 5,
      filterCacheKey: null,
      prefsCacheKey: "hotelReviewPrefs",
      needKeywords: false,
      keywordVariant: "location_keywords_v2_llr_order_30_en"
    }
  });

  const getReviews = R.pipe(
    R.path(["data", "locations"]),
    R.head,
    R.path(["reviewList", "reviews"]),
    R.map(R.pick(["id", "createdDate", "rating", "text", "title", "url"]))
  );
</script>

{#await $reviews}
  <!-- <Spinner /> -->
{:then result}
  {#each getReviews(result) as review (review.id)}
    <ReviewCard {review} />
  {/each}
{:catch error}
  <h2>Refresh page</h2>
  <!-- <h3>{error}</h3> -->
{/await}
